import { loadStyles } from '@microsoft/load-themed-styles';

const styles = {
  currentDate: 'currentDate_3563c3c9',
  disableProgress: 'disableProgress_20148850',
};

export default styles;

loadStyles('.currentDate_3563c3c9{border-left:2px dashed red!important}.disableProgress_20148850 .dx-gantt-task-edit-progress{display:none!important}');
